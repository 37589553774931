import React from "react";
import { useClient } from "../../hooks/useClient";
import { usePersist } from "../../hooks/usePersist";
import "./Colorizer.scss";

export default function Colorizer({ children }) {
  const { client } = useClient();
  const { fontSize } = usePersist();
  return (
    <div
      className="Colorizer"
      style={{
        "--accent-color": client?.color || "#e33e09",
        fontSize: fontSize,
      }}
    >
      {children}
    </div>
  );
}
