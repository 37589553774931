import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Layout from "../../atoms/Layout/Layout";
import QrButton from "../../atoms/QrButton/QrButton";
import { useClient } from "../../hooks/useClient";
import useQuery from "../../hooks/useQuery";
import "./ExponatList.scss";

export default function ExponatList() {
  let { client } = useClient();

  const { t } = useTranslation();

  //fetch all points
  let { mappedResults } = useQuery(
    "exhibits",
    "user",
    "==",
    client.id,
    "isPOI",
    "==",
    false
  );
  console.log(mappedResults)
  return (
    <Layout hideHome>
      <div className="exponatListTitle">{t("Exponate")}</div>
      <div className="ExponatList">
        {mappedResults &&
          mappedResults.filter(e=>e.point).map((exhibit) => {
            return <ExponatItem key={exhibit.id} data={exhibit} />;
          })}
      </div>
      <QrButton></QrButton>
    </Layout>
  );
}

function ExponatItem({ data }) {
  return (
    <Link to={"/exponat/" + data.id} className="exponatItem box shadow">
      <div className="exponatImage">
        <img alt="Exponat Bild" src={data.images[0]}></img>
      </div>
      <div className="exponatInfo">
        <div className="exponatName">{data.name}</div>
      </div>
    </Link>
  );
}
