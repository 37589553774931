const es = {
  Karte: "Mapa",
  Touren: "Tours",
  "Sprache ändern": "Cambiar idioma",
  "Interaktiver Museumsbegleiter": "Guía de museo interactivo",
  "Geschätzte dauer": "Duración estimada",
  "Tour starten": "Iniciar la visita",
  Exponate: "Exhibiciones",
  Home: "Inicio",
  Führungen: "Visitas",
  Sprache: "Idioma",
  Datenschutz: "Política de privacidad",
  Schnitzeljagden: "Búsquedas",
  "Die Tour wird geladen": "La visita está cargando",
  Bilder: "Imágenes",
  "Mehr erfahren": "Más información",
  Daten: "Datos",
  "Erlebe das Exponat in 3D": "Experiencia 3D",
  Videos: "Vídeos",
  Akzeptieren: "Aceptar",
  "Video von Youtube einbinden?": "¿Incluir video de YouTube?",
  "Es gelten die Youtube Datenschutzbedingungen":
    "Los términos de privacidad de YouTube se aplican",
  "Code eingeben": "Introducir código",
  "QR-Code scannen": "Escanea el código QR",
  Schließen: "Cerrar",
  "Code scannen": "Escanear código",
  "Richtig!": "¡Correcto!",
  "Gefunden!": "¡Encontrado!",
  "Leider falsch!": "¡Desafortunadamente, incorrecto!",
  "Nicht gefunden.": "No encontrado.",
  "Fehler beim Freischalten": "Error al desbloquear",
  "Der Code wurde bereits verwendet": "El código ya se ha utilizado",
  "Freischaltcode eingeben": "Introducir código de desbloqueo",
  Freischalten: "Desbloquear",
  "Jetzt freischalten": "Desbloquear ahora",
  "Erneut probieren": "Volver a intentarlo",
  "Sie haben den Code aaa gescannt.": "Ha escaneado el código",
  "gescannt.": ".",
  "Um diesen zum freischalten zu verwenden, klicken Sie auf den Button unten.":
    "Para usar este código para desbloquear, haga clic en el botón de abajo.",
  oder: "o",
};
export default es;
