const de = {
  Karte: "Karte",
  Touren: "Führungen",
  "Sprache ändern": "Sprache ändern",
  "Interaktiver Museumsbegleiter": "Interaktiver Museumsbegleiter",
  "Geschätzte dauer": "Geschätzte Dauer",
  "Tour starten": "Tour starten",
  "Exponate in dieser Tour": "Exponate in dieser Tour",
};
export default de;
