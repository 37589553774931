import React, { useRef, useState } from "react";
import Layout from "../../atoms/Layout/Layout";
import Map, { DumnDot } from "../../atoms/Map/Map";
import "./TourMap.scss";

import AudioPlayer from "../../atoms/AudioPlayer/AudioPlayer";
import RoundIconButton from "../../atoms/RoundIconButton/RoundIconButton";
import { FaArrowLeft, FaArrowRight, FaCheck, FaTimes } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { usePersist } from "../../hooks/usePersist";
import { ProjectTranslateableDocument } from "../../hooks/useTranslateDocument";
import Spacer from "../../atoms/Spacer/Spacer";
import SwipeGallery from "../../atoms/SwipeGallery/SwipeGallery";
import Loading from "react-loading";
import { useTranslation } from "react-i18next";
import { useDrag } from "@use-gesture/react";
import { useSpring, animated } from "@react-spring/web";
import SingleExponat from "../../atoms/SingleExponat/SingleExponat";
import { useClient } from "../../hooks/useClient";

export default function TourMap() {
  let { id, station } = useParams();
  const camRef = useRef();
  station = parseInt(station);
  const { language } = usePersist();
  const [tourData, setTourData] = useState(null);
  const nav = useNavigate();
  const { t } = useTranslation();
  const { client } = useClient();
  useEffect(() => {
    const functions = getFunctions();
    const getTourData = httpsCallable(functions, "getTourData");
    getTourData({ tourId: id }).then(({ data }) => {
      setTourData({
        tour: ProjectTranslateableDocument(data, language),
        exhibits: data.exhibits.map((d) => {
          return ProjectTranslateableDocument(d, language);
        }),
        pois: data.pois.map((d) => {
          return ProjectTranslateableDocument(d, language);
        }),
      });
    });
  }, [id, language]);

  useEffect(() => {
    let tid = setTimeout(() => {
      if (
        camRef.current &&
        tourData &&
        tourData.exhibits &&
        tourData.exhibits[parseInt(station)]
      ) {
        const point = tourData.exhibits[parseInt(station)];

        camRef.current.moveTo(point.point.x, point.point.y - 2.5, 30, true);
        camRef.current.zoomTo(60, true);
      } else {
        console.log("not doing it", camRef.current, tourData, station);
      }
    }, 100);
    return () => {
      clearTimeout(tid);
    };
  }, [station, tourData]);
  return (
    <Layout className="MapScreen" backUrl={"/tours"}>
      {tourData ? (
        <>
          <Map ref={camRef}>
            {tourData.exhibits &&
              tourData.exhibits.map((exhibit, i) => {
                return (
                  <DumnDot
                    data={exhibit}
                    key={exhibit.id}
                    onClick={() => {
                      nav("/tour/" + id + "/" + i);
                    }}
                  ></DumnDot>
                );
              })}
            {tourData.pois &&
              tourData.pois.map((exhibit, i) => {
                return (
                  <DumnDot
                    data={exhibit}
                    key={exhibit.id}
                    onClick={() => {}}
                  ></DumnDot>
                );
              })}
          </Map>
          <TourExponatOverlay
            data={tourData.exhibits[station]}
            exhibits={tourData.exhibits}
            station={station}
            nextButton={
              tourData.exhibits.length > station + 1 ? null : (
                <RoundIconButton to="/tourComplete" className="greenButton">
                  <FaCheck></FaCheck>
                </RoundIconButton>
              )
            }
          ></TourExponatOverlay>
        </>
      ) : (
        <div className="fullLoader">
          <Loading
            type="spin"
            color={client?.color || "#E33E09"}
            height={100}
            width={100}
          />
          <div className="loadingText">{t("Die Tour wird geladen")}</div>
        </div>
      )}
    </Layout>
  );
}
//

export function TourExponatOverlay({
  data,
  children,
  onClose,
  station,
  exhibits,
  nextButton,
}) {
  const [imageShown, setImageShown] = useState(false);
  const nav = useNavigate();
  const scrollRef = useRef();
  const [open, setOpen] = useState(false);
  const bind = useDrag(
    (state) => {
      console.log(state);
      if (!open) {
        if (state.direction[1] === -1 && state.movement[1] < -10) {
          setOpen(true);
        }
        /*
                if (onClose && state.direction[1] === 1 && state.movement[1] > 10) {
          setHidden(true);
          setTimeout(() => {
            onClose();
          }, 300);
          //onClose && onClose();
        }

      */
      }
      if (open) {
        if (state.direction[1] === 1 && state.movement[1] > 10) {
          console.log("OPEN!!");
          setOpen(false);

          scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
        }
      }
    },
    { filterTaps: true }
  );

  const bind2 = useDrag(
    (state) => {
      console.log(state);
      if (open) {
        if (state.direction[1] === 1 && state.movement[1] > 10) {
          console.log("OPEN!!");
          setOpen(false);

          scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
        }
      }
      if (!open) {
        if (state.direction[1] === -1 && state.movement[1] < -10) {
          console.log("EOP!N");
          setOpen(true);
        }
      }
    },
    { filterTaps: true }
  );

  const props = useSpring({
    flex: open ? 1 : 0,
    overflowY: open ? "auto" : "hidden",
    touchAction: open ? "auto" : "none",
  });

  return (
    <>
      <div className="TourExponatOverlay">
        <Spacer h={80}></Spacer>
        <animated.div style={props} className="expoTop shadow" ref={scrollRef}>
          <div
            className="grabBar"
            {...bind2()}
            onClick={() => {
              if (open) {
                setOpen(false);
                scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
              } else {
                setOpen(true);
              }
            }}
          >
            <div className="grabBarInner"></div>
            {onClose && (
              <div className="closeOverlayButton" onClick={onClose}>
                <FaTimes></FaTimes>
              </div>
            )}
          </div>
          <div
            {...bind()}
            onClick={() => {
              if (!open) {
                setOpen(true);
              }
            }}
            className={"grabOverlay " + (open ? "open " : " ")}
          ></div>

          <SingleExponat data={data}></SingleExponat>
        </animated.div>
        <Spacer h={10}></Spacer>
        {data.audio && (
          <>
            <div className="expoAudio shadow">
              <AudioPlayer src={data.audio}></AudioPlayer>
            </div>
            <Spacer h={10}></Spacer>
          </>
        )}
        <div className="buttons">
          {exhibits && (
            <RoundIconButton
              onClick={() => station > 0 && nav("./../" + (station - 1))}
              className={station > 0 ? "" : "disabled"}
            >
              <FaArrowLeft></FaArrowLeft>
            </RoundIconButton>
          )}

          {exhibits && !nextButton && (
            <RoundIconButton
              onClick={() =>
                station + 1 < exhibits.length && nav("./../" + (station + 1))
              }
              className={station + 1 < exhibits.length ? "" : "disabled"}
            >
              <FaArrowRight></FaArrowRight>
            </RoundIconButton>
          )}
          {nextButton && nextButton}
        </div>
        {(exhibits || nextButton) && <Spacer h={10}></Spacer>}
      </div>
      {children}

      {imageShown !== false && (
        <SwipeGallery
          start={imageShown}
          onClose={() => setImageShown(false)}
          images={data.images}
        ></SwipeGallery>
      )}
    </>
  );
}
