import React from "react";
import { usePersist } from "../../hooks/usePersist";
import Button from "../Button/Button";

import LanguageSelector from "../LanguageSelector/LanguageSelector";
import Modal from "../Modal/Modal";
import Nav from "../Nav/Nav";
import "./Layout.scss";

export default function Layout({ children, className, backUrl, hideBack }) {
  let { language, hasCookieAccepted, setHasCookieAccepted } = usePersist();
  return (
    <div className={"Layout"}>
      <Nav backUrl={backUrl} hideBack={hideBack}></Nav>
      {!language && (
        <Modal>
          <LanguageSelector></LanguageSelector>
        </Modal>
      )}
      {false && !hasCookieAccepted && (
        <Modal>
          <div className="cookieModal">
            Cookies!
            <Button
              onClick={() => {
                setHasCookieAccepted(true);
              }}
            >
              Akzeptieren
            </Button>
          </div>
        </Modal>
      )}
      <div className={"inner " + className}>{children}</div>
    </div>
  );
}
