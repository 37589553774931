import React from "react";
import { Link } from "react-router-dom";
import "./Button.scss";

export default function Button({ to, children, icon, onClick, className }) {
  return (
    <Link
      to={to}
      className={`Button ${className || ""}`}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
          e.preventDefault();
          onClick(e);
        }
      }}
    >
      {icon && <div className="icon">{icon}</div>}
      {children}
    </Link>
  );
}
