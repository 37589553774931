import { Canvas, useFrame } from "@react-three/fiber";
import React, { useEffect, useMemo, useRef, forwardRef } from "react";

import { a } from "@react-spring/three";
import "./Map.scss";
import { useClient } from "../../hooks/useClient";
import { usePersist } from "../../hooks/usePersist";
import { ProjectTranslateableDocument } from "../../hooks/useTranslateDocument";
import { useTexture } from "@react-three/drei";
import { CameraControls } from "../../utils/camera-controls";
//import useCompass from "../../hooks/useCompass";
const Map = forwardRef(({ children }, camRef) => {
  let { client } = useClient();
  let { language } = usePersist();
  //memoize client based on langauge:
  let mappedClient = useMemo(
    () => ProjectTranslateableDocument(client, language),
    [client, language]
  );

  //const compassRef = useRef();

  //const { compass, startCompass } = useCompass();
  //console.log(compass);
  /*useEffect(() => {
    //rotate compass by compass degrees
    if (compassRef.current) {
      compassRef.current.style.transform = `rotate(${-compass}deg)`;
    }
  }, [compass, compassRef]);
*/
  return (
    <>
      <Canvas
        style={{ flex: 1 }}
        orthographic
        camera={{ zoom: 60 }}
        onCreated={({ gl }) => {
          gl.setClearColor("#f2f2f2");
        }}
      >
        <CameraControls ref={camRef}></CameraControls>
        {children}
        {mappedClient && mappedClient.floorplan ? (
          <Floor
            background={
              mappedClient.floorplan ||
              "https://firebasestorage.googleapis.com/v0/b/appvemus.appspot.com/o/1cf9ed48-bc80-4ad1-b963-eea843f29411?alt=media&token=351c8f88-12dc-4e4f-be60-f8828287c4ac"
            }
          ></Floor>
        ) : (
          <Floor
            background={
              "https://firebasestorage.googleapis.com/v0/b/appvemus.appspot.com/o/1cf9ed48-bc80-4ad1-b963-eea843f29411?alt=media&token=351c8f88-12dc-4e4f-be60-f8828287c4ac"
            }
          ></Floor>
        )}
      </Canvas>
    </>
  );
});

export default Map;
/*
function LocationDot() {
  const boxRef = useRef();
  const { compass } = useCompass();
  useEffect(() => {
    //set box rotation to compass degrees along z
    if (boxRef.current) {
      boxRef.current.rotation.z = (-compass * Math.PI) / 180;
    }
  }, [boxRef, compass]);
  return (
    <Box rotation={[0, 0, 1]} ref={boxRef}>
      <meshNormalMaterial></meshNormalMaterial>
    </Box>
  );
}
*/

export function Floor({ background }) {
  let tex = useTexture(background);
  let floorRef = useRef();
  useEffect(() => {
    let aspect = tex.image.width / tex.image.height;

    floorRef.current.scale.set(aspect, 1, 1);
  }, [tex, floorRef]);

  return (
    <mesh ref={floorRef} position={[0, 0, -2]}>
      <planeGeometry args={[10, 10, 10]}></planeGeometry>
      <meshBasicMaterial map={tex}></meshBasicMaterial>
    </mesh>
  );
}

export function DumnDot({ data, onClick = () => {} }) {
  const isImageDot = data.style.thumbnail && data.style.type === "image";
  let colorMap = useTexture(isImageDot ? data.style.thumbnail : "/dot.png");

  let circlealpha = useTexture("/circlealpha.png");
  let dotalpha = useTexture("/dotalpha.png");
  let dotRef = useRef();
  let thePos = data.point || {};
  useFrame(({ camera }) => {
    //adjust dot size with zoom
    if (dotRef.current) {
      const zoom = camera.zoom;
      dotRef.current.scale.set(
        ((0.25 * (data.style.size || 1)) / Math.max(zoom, 50)) * 60,
        ((0.25 * (data.style.size || 1)) / Math.max(zoom, 50)) * 60,
        ((0.25 * (data.style.size || 1)) / Math.max(zoom, 50)) * 60
      );
    }
  });

  return (
    <mesh
      ref={dotRef}
      onClick={(e) => {
        onClick();
      }}
      position={[thePos.x, thePos.y, thePos.z]}
      scale={0.25 * (data.style.size || 1)}
    >
      <planeGeometry args={[1, 1, 1]}></planeGeometry>
      {isImageDot && (
        <a.meshBasicMaterial
          transparent
          map={colorMap}
          alphaMap={dotalpha}
          alphaTest={0.5}
          opacity={1}
        ></a.meshBasicMaterial>
      )}
      {data.style.type === "dot" && (
        <a.meshBasicMaterial
          transparent
          color={data.style.color || "red"}
          alphaMap={dotalpha}
          alphaTest={0.5}
          opacity={1}
        ></a.meshBasicMaterial>
      )}
      {data.style.type === "circle" && (
        <a.meshBasicMaterial
          transparent
          color={data.style.color || "red"}
          alphaMap={circlealpha}
          alphaTest={0.5}
          opacity={1}
        ></a.meshBasicMaterial>
      )}
    </mesh>
  );
}
