import {
  Center,
  Environment,
  Html,
  OrbitControls,
  PerspectiveCamera,
  useFBX,
  useGLTF,
} from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import React, { Suspense, useCallback, useRef } from "react";
import "./ThreeDeeViewer.scss";

export default function ThreeDeeViewer({ data, noScroll }) {
  let controller = useRef();
  let cameraRef = useRef();

  let glRef = useRef();

  const measuredRef = useCallback(
    (node) => {
      if (node !== null) {
        node.setPolarAngle(1);
        node.setAzimuthalAngle(-0.5);
        node.setPolarAngle(data.angle);
      }
      controller.current = node;
    },
    [data.angle]
  );
  return (
    <div className="ThreeDeeViewer">
      <Canvas
        linear
        style={{
          pointerEvents: noScroll ? "none" : "auto",
          touchAction: noScroll ? "none" : "auto",
        }}
        onCreated={({ gl }) => {
          if (data.color) {
            gl.setClearColor(data.color);
          }
          glRef.current = gl;
        }}
      >
        <Suspense
          fallback={
            <Html style={{ color: "#f2f2f2", fontWeight: "bold" }}>
              Loading
            </Html>
          }
        >
          <PerspectiveCamera
            position={[0, 0, 4]}
            fov={data.fov}
            makeDefault
            ref={cameraRef}
          ></PerspectiveCamera>
          <Center>
            {data && data.filename &&data.filename.toLowerCase().endsWith(".gltf") && (
              <GLTFModel src={data.file} scale={data.size}></GLTFModel>
            )}
            {data && data.filename &&data.filename.toLowerCase().endsWith(".fbx") && (
              <FBXModel src={data.file} scale={data.size}></FBXModel>
            )}
          </Center>
          <OrbitControls
            enableZoom={!noScroll}
            enablePan={false}
            enableRotate={!noScroll}
            autoRotateSpeed={data.rotateSpeed}
            camera={cameraRef.current}
            autoRotate
            ref={measuredRef}
          ></OrbitControls>
          <ambientLight intensity={0.2}></ambientLight>
          <pointLight
            position={[-15, 25, -5]}
            intensity={data.light * 0.1}
            castShadow
            shadow-mapSize-height={512}
            shadow-mapSize-width={512}
            shadow-radius={10}
            shadow-bias={-0.0001}
          />
          <pointLight
            position={[-15, 15, -15]}
            intensity={data.light * 0.1}
            castShadow
            shadow-mapSize-height={512}
            shadow-mapSize-width={512}
            shadow-radius={10}
            shadow-bias={-0.0001}
          />
          <pointLight
            position={[0, 15, -15]}
            intensity={data.light * 0.7}
            castShadow
            shadow-mapSize-height={1024}
            shadow-mapSize-width={1024}
            shadow-radius={20}
            shadow-bias={-0.0001}
          />
          <pointLight
            position={[0, 15, 15]}
            intensity={data.light * 0.7}
            castShadow
            shadow-mapSize-height={1024}
            shadow-mapSize-width={1024}
            shadow-radius={20}
            shadow-bias={-0.0001}
          />
          {data.env && (
            <Environment background={"only"} files={data.env}></Environment>
          )}
        </Suspense>
      </Canvas>
    </div>
  );
}

function GLTFModel({ scale, src }) {
  let model = useGLTF(src);
  return (
    <group scale={0.05 * scale}>
      <primitive object={model.scene.clone()} />
    </group>
  );
}

function FBXModel({ scale, src }) {
  let fbx = useFBX(src);
  return (
    <group scale={0.05 * scale}>
      <primitive object={fbx} />
    </group>
  );
}
