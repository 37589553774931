const pl = {
  Karte: "Karta",
  Touren: "Wycieczki",
  "Sprache ändern": "Zmień język",
  "Interaktiver Museumsbegleiter": "Interaktywny przewodnik po muzeum",
  "Geschätzte dauer": "Szacowany czas trwania",
  "Tour starten": "Rozpocznij wycieczkę",
  Exponate: "Eksponaty",
  Home: "Strona główna",
  Führungen: "Przewodniki",
  Sprache: "Język",
  Datenschutz: "Polityka prywatności",
  Schnitzeljagden: "Polowanie na sznycla",
  "Die Tour wird geladen": "Wycieczka jest ładowana",
  Bilder: "Obrazy",
  "Mehr erfahren": "Dowiedz się więcej",
  Daten: "Dane",
  "Erlebe das Exponat in 3D": "Doświadcz eksponatu w 3D",
  Videos: "Filmy",
  Akzeptieren: "Akceptuj",
  "Video von Youtube einbinden?": "Osadzić film z Youtube?",
  "Es gelten die Youtube Datenschutzbedingungen":
    "Obowiązują zasady ochrony prywatności Youtube",
  "Code eingeben": "Wprowadź kod",
  "QR-Code scannen": "Skanuj kod QR",
  Schließen: "Zamknij",
  "Code scannen": "Skanuj kod",
  "Richtig!": "Dobrze!",
  "Gefunden!": "Znaleziono!",
  "Leider falsch!": "Niestety źle!",
  "Nicht gefunden.": "Nie znaleziono.",
  "Fehler beim Freischalten": "Błąd podczas odblokowywania",
  "Der Code wurde bereits verwendet": "Kod został już użyty",
  "Freischaltcode eingeben": "Wprowadź kod odblokowujący",
  Freischalten: "Odblokuj",
  "Jetzt freischalten": "Odblokuj teraz",
  "Erneut probieren": "Spróbuj ponownie",
  "Sie haben den Code": "Zeskanowałeś kod",
  "gescannt.": ".",
  "Um diesen zum freischalten zu verwenden, klicken Sie auf den Button unten.":
    "Aby odblokować, kliknij poniższy przycisk.",
  oder: "lub",
};
export default pl;
