import React from "react";
import Layout from "../../atoms/Layout/Layout";
import "./Unlock.scss";
import { ScanComponent } from "../../atoms/Scanner/Scanner";
import Button from "../../atoms/Button/Button";
import Spacer from "../../atoms/Spacer/Spacer";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useClient } from "../../hooks/useClient";
import Loading from "react-loading";
import { FaCheck, FaExclamationTriangle } from "react-icons/fa";
import { usePersist } from "../../hooks/usePersist";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function Unlock() {
  const functions = getFunctions();
  const sendUnlock = httpsCallable(functions, "useUnlockCode");
  const [code, setCode] = React.useState("");
  const [status, setStatus] = React.useState("start");
  const { client } = useClient();
  const { setUnlockedUntil } = usePersist();
  const nav = useNavigate();
  const { t } = useTranslation();
  return (
    <Layout className="Unlock">
      <ScanComponent
        status={""}
        onScan={(data) => {
          console.log(data);
          if (data && data.text) {
            nav("/unlock/" + data.text.split("/").pop());
          }
        }}
      />
      <Spacer h={12}></Spacer>
      <div className="centerOr">{t("oder")}</div>
      <Spacer h={12}></Spacer>
      <input
        placeholder={t("Freischaltcode eingeben")}
        className="codeInput"
        type={"text"}
        value={code}
        onChange={(e) => {
          setCode(e.target.value);
        }}
      ></input>
      <Spacer h={12}></Spacer>
      {status === "error" && (
        <>
          <div className="error">{t("Fehler beim Freischalten")}</div>
          <Spacer h={12}></Spacer>
        </>
      )}

      {status === "used" && (
        <>
          <div className="error">{t("Der Code wurde bereits verwendet")}</div>
          <Spacer h={12}></Spacer>
        </>
      )}
      <Button
        onClick={() => {
          if (!["start", "error", "used"].includes(status)) return;
          setStatus("loading");
          sendUnlock({ user: client.id, code: code.toLowerCase() })
            .then((r) => {
              if (r.data.status === "OK") {
                setStatus("done");
                // set unlocked for 24 hours.
                setUnlockedUntil(new Date().getTime() + 24 * 60 * 60 * 1000);
                nav("/");
              } else if (r.data.status === "USED") {
                setStatus("used");
              } else {
                setStatus("error");
              }
              console.log(r);
            })
            .catch((e) => {
              setStatus("error");
            });
        }}
      >
        {status === "start" && t("Freischalten")}
        {status === "done" && <FaCheck></FaCheck>}
        {status === "loading" && (
          <Loading type="spin" color={"white"} width={20} height={20}></Loading>
        )}
        {status === "error" && <FaExclamationTriangle></FaExclamationTriangle>}
        {status === "used" && <FaExclamationTriangle></FaExclamationTriangle>}
      </Button>
    </Layout>
  );
}
