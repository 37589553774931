import React from "react";
import { useClient } from "../../hooks/useClient";
import { usePersist } from "../../hooks/usePersist";
import { ProjectTranslateableDocument } from "../../hooks/useTranslateDocument";
import Spacer from "../Spacer/Spacer";
import "./LanguageSelector.scss";
const languageMap = {
  DE: {
    name: "Deutsch",
    flag: "de",
  },
  EN: {
    name: "English",
    flag: "gb",
  },
  ES: {
    name: "Español",
    flag: "es",
  },
  FR: {
    name: "Français",
    flag: "fr",
  },
  NL: {
    name: "Nederlands",
    flag: "nl",
  },
  PL: {
    name: "Polski",
    flag: "pl",
  },
  DE_Kinder: {
    name: "Deutsch für Kinder",
    flag: "de",
  },
};
export default function LanguageSelector({ onSelect }) {
  let client = useClient((s) => s.client);

  let translatedClient = ProjectTranslateableDocument(client, "DE");
  return (
    <div className="LanguageSelector">
      <div className="museumName">{translatedClient.name}</div>
      <Spacer h={120} />
      Willkommen
      <Spacer h={20} />
      <div className="chooselanguage">Wähle deine Sprache</div>
      <Spacer h={30} />
      <LanguageList onSelect={onSelect}></LanguageList>
    </div>
  );
}

export function LanguageList({ onSelect }) {
  let client = useClient((s) => s.client);
  let { setLanguage } = usePersist();

  return (
    <div className="languages languageList">
      {client?.languages?.map((l) => {
        let language = languageMap[l];
        return (
          <div
            className="langOption"
            key={l}
            onClick={() => {
              setLanguage(l);
              onSelect && onSelect();
            }}
          >
            <div className="flag">
              <img
                alt={"flag " + l}
                src={"/flags/" + language.flag + ".svg"}
              ></img>
            </div>
            <div className="languageName">{language.name}</div>
          </div>
        );
      })}
    </div>
  );
}
