// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyASUAGlfWj23hns0tFSodpFG3W5vZil5Ig",
  authDomain: "appvemus.firebaseapp.com",
  projectId: "appvemus",
  storageBucket: "appvemus.appspot.com",
  messagingSenderId: "1046065717352",
  appId: "1:1046065717352:web:4f42a282ef24547e4a9bdd",
  measurementId: "G-JGECB7TQ60",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);

export default app;
