import React from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import Button from "../../atoms/Button/Button";
import Layout from "../../atoms/Layout/Layout";
import Spacer from "../../atoms/Spacer/Spacer";
import "./TourComplete.scss";

export default function TourComplete() {
  return (
    <Layout className="TourComplete">
      <div className="center">
        <div className="icon">
          <FaRegCheckCircle />
        </div>
        Sie haben die Tour erfolgreich abgeschlossen!
      </div>
      <Button to="/map">Zurück zur Karte</Button>
      <Spacer h={10}></Spacer>
      <Button to="/tours">Weitere Tour starten</Button>
      <Spacer h={10}></Spacer>
    </Layout>
  );
}
