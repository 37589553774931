import React, { useRef, useState } from "react";
import Layout from "../../atoms/Layout/Layout";
import "./TreasureHunt.scss";
import Map, { DumnDot } from "../../atoms/Map/Map";
import Button from "../../atoms/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { usePersist } from "../../hooks/usePersist";
import { ProjectTranslateableDocument } from "../../hooks/useTranslateDocument";

import Spacer from "../../atoms/Spacer/Spacer";
import Scanner from "../../atoms/Scanner/Scanner";
import { resolveShortLink } from "../ShortLink/ShortLink";
import { useClient } from "../../hooks/useClient";
import { TourExponatOverlay } from "../TourMap/TourMap";
import RoundIconButton from "../../atoms/RoundIconButton/RoundIconButton";
import { FaArrowRight, FaCheck } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import Loading from "react-loading";
export default function TreasureHunt() {
  let { id, station, solved } = useParams();
  const { client } = useClient();
  solved = solved === "true";
  const camRef = useRef();
  station = parseInt(station);
  const { language } = usePersist();
  const [tourData, setTourData] = useState(null);
  const [scannerOpen, setScannerOpen] = useState(false);
  const nav = useNavigate();
  const [scannerState, setScannerState] = useState("start");
  const { t } = useTranslation();
  useEffect(() => {
    const functions = getFunctions();
    const getTourData = httpsCallable(functions, "getTourData");
    getTourData({ tourId: id }).then(({ data }) => {
      setTourData({
        tour: ProjectTranslateableDocument(data.tour, language),
        exhibits: data.exhibits.map((d) => {
          return ProjectTranslateableDocument(d, language);
        }),
        pois: data.pois.map((d) => {
          return ProjectTranslateableDocument(d, language);
        }),
      });
    });
  }, [id, language]);

  useEffect(() => {
    let tid = setTimeout(() => {
      if (
        camRef.current &&
        tourData &&
        tourData.exhibits &&
        tourData.exhibits[parseInt(station)] &&
        solved
      ) {
        const point = tourData.exhibits[parseInt(station)];

        camRef.current.moveTo(point.point.x, point.point.y - 2.5, 30, true);
        camRef.current.zoomTo(60, true);
      }
    }, 100);
    return () => {
      clearTimeout(tid);
    };
  }, [station, tourData, solved]);
  console.log(tourData);
  return (
    <Layout className="MapScreen" backUrl={"/tours"}>
      {tourData ? (
        <>
          <Map ref={camRef}>
            {tourData.exhibits &&
              tourData.exhibits.map((exhibit, i) => {
                if (i > station) {
                  return null;
                }
                if (i >= station && !solved) {
                  return null;
                }
                return (
                  <DumnDot
                    data={exhibit}
                    key={exhibit.id}
                    onClick={() => {
                      //nav("/treasure/" + id + "/" + i + "/true");
                    }}
                  ></DumnDot>
                );
              })}
            {tourData.pois &&
              tourData.pois.map((exhibit, i) => {
                return (
                  <DumnDot
                    data={exhibit}
                    key={exhibit.id}
                    onClick={() => {}}
                  ></DumnDot>
                );
              })}
          </Map>
          {solved && (
            <TourExponatOverlay
              solved
              data={tourData.exhibits[station]}
              nextButton={
                tourData.exhibits.length > station + 1 ? (
                  <RoundIconButton
                    className="greenButton"
                    to={"./../../" + (station + 1) + "/false"}
                  >
                    <FaArrowRight></FaArrowRight>
                  </RoundIconButton>
                ) : (
                  <RoundIconButton to="/tourComplete" className="greenButton">
                    <FaCheck></FaCheck>
                  </RoundIconButton>
                )
              }
            ></TourExponatOverlay>
          )}
          {!solved && (
            <TreasureTextContainer
              id={id}
              station={station}
              data={tourData.exhibits[station]}
              treasureText={
                tourData.tour.pointTexts &&
                tourData.tour.pointTexts[
                  tourData.exhibits[parseInt(station)].id
                ]
              }
              treasureImage={
                tourData.tour.pointImages &&
                tourData.tour.pointImages[
                  tourData.exhibits[parseInt(station)].id
                ]
              }
              setScannerOpen={setScannerOpen}
            ></TreasureTextContainer>
          )}
        </>
      ) : (
        <div className="fullLoader">
          <Loading
            type="spin"
            color={client?.color || "#E33E09"}
            height={100}
            width={100}
          />
          <div className="loadingText">{t("Die Tour wird geladen")}</div>
        </div>
      )}

      {scannerOpen && (
        <Scanner
          errorMessage={t("Leider falsch!")}
          status={scannerState}
          onClose={() => {
            setScannerOpen(false);
          }}
          onScan={(shortLink) => {
            resolveShortLink(shortLink, client.id)
              .then((doc) => {
                console.log("!!!");
                console.log(doc, tourData.exhibits[parseInt(station)].id);
                if (doc.targetId === tourData.exhibits[parseInt(station)].id) {
                  setScannerState("success");
                  setTimeout(() => {
                    setScannerState("start");
                    setScannerOpen(false);
                    nav("/treasure/" + id + "/" + station + "/true");
                  }, 2000);
                } else {
                  setScannerState("error");
                  setTimeout(() => {
                    setScannerState("start");
                  }, 2000);
                }
              })
              .catch(() => {
                setScannerState("error");
                setTimeout(() => {
                  setScannerState("start");
                }, 2000);
              });
          }}
        ></Scanner>
      )}
    </Layout>
  );
}
//
function TreasureTextContainer({
  treasureText,
  treasureImage,
  data,
  setScannerOpen,
  id,
  station,
}) {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const nav = useNavigate();
  return (
    <>
      {modalOpen && (
        <div
          className="treasureModal"
          onClick={() => {
            setModalOpen(false);
          }}
        >
          <div
            className="treasureModalInner box shadow"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Spacer h={12}></Spacer>
            Du kannst das Exponat<br></br>
            <b style={{ fontSize: "130%" }}>{data.name}</b>
            <br></br>
            nicht finden?
            <Spacer h={24}></Spacer>
            <Button
              onClick={() => {
                setModalOpen(false);
              }}
            >
              Ich suche weiter!
            </Button>
            <Spacer h={8}></Spacer>
            <Button
              onClick={() => {
                nav("/treasure/" + id + "/" + station + "/true");
              }}
              className={"greenButton"}
            >
              Auflösung anzeigen!
            </Button>
          </div>
        </div>
      )}
      <div className="treasureTextContainer">
        <div className="treasureText">
          <div className="title">???</div>
          {treasureImage && (
            <div className="treasureImageCont">
              <img
                alt="treasure hint"
                className="treasureImage"
                src={treasureImage}
              ></img>
            </div>
          )}
          {treasureText}
        </div>
        <Spacer h={10}></Spacer>
        <div className="flex">
          <Button
            onClick={() => setModalOpen(true)}
            className={"questionmarkButton"}
          >
            ?
          </Button>
          <Spacer w={10}></Spacer>
          <Button
            className={"greenButton"}
            onClick={() => {
              setScannerOpen(true);
            }}
          >
            {t("Gefunden!")}
          </Button>
        </div>
      </div>
    </>
  );
}
