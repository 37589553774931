import React from "react";
import Layout from "../../atoms/Layout/Layout";
import "./Home.scss";
import useTranslateDocument from "../../hooks/useTranslateDocument";

import { useTranslation } from "react-i18next";
import Button from "../../atoms/Button/Button";
import Spacer from "../../atoms/Spacer/Spacer";
import { FaGraduationCap, FaTrophy } from "react-icons/fa";
import QrButton from "../../atoms/QrButton/QrButton";
import { useClient } from "../../hooks/useClient";
import { usePersist } from "../../hooks/usePersist";
import useQuery from "../../hooks/useQuery";
export default function Home() {
  const { client } = useClient();
  let { data } = useTranslateDocument("/clients/" + client.id);
  const { exponateMode } = usePersist();
  const { t } = useTranslation();

  let { mappedResults, status } = useQuery("tours", "user", "==", client.id);

  return (
    <Layout className="Home" hideBack>
      <div className="welcomeImage shadow">
        <img
          alt="museum"
          src={
            data.image ||
            "https://firebasestorage.googleapis.com/v0/b/appvemus.appspot.com/o/1cf9ed48-bc80-4ad1-b963-eea843f29411?alt=media&token=351c8f88-12dc-4e4f-be60-f8828287c4ac"
          }
        ></img>
      </div>
      <div className="MuseumName">
        <div className="nameText">{data.name}</div>
      </div>
      <div className="description">{data.description}</div>
      <Spacer h={160}></Spacer>
      <div className="flex1"></div>

      <div className="buttons">
        {(status === "loading" ||
          status === "start" ||
          mappedResults.filter((t) => t.isEnabled).length > 0) && (
          <>
            <Button icon={<FaGraduationCap />} to="/tours">
              {t("Touren")}
            </Button>
            <Spacer h={10}></Spacer>
          </>
        )}
        <Button
          icon={<FaTrophy></FaTrophy>}
          to={
            exponateMode
              ? exponateMode === "map"
                ? "/map"
                : "/exponate"
              : "/exponateModeSelect"
          }
        >
          {t("Exponate")}
        </Button>
        <Spacer h={10}></Spacer>
      </div>
      <QrButton></QrButton>
    </Layout>
  );
}
