import React from "react";
import ReactAudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

import "./AudioPlayer.scss";

import { isIOS } from "react-device-detect";

const AudioPlayer = React.forwardRef(({ src }, ref) => {
  return (
    <div className="AudioPlayer">
      <div className="audioControls">
        <div className="progressBar">
          <div className="progressInner"></div>
        </div>
      </div>

      <ReactAudioPlayer
        src={src}
        ref={ref}
        autoPlay={false}
        autoPlayAfterSrcChange={false}
        // other props here
        layout={isIOS ? "horizontal" : "stacked"}
        customControlsSection={
          isIOS ? ["MAIN_CONTROLS"] : ["MAIN_CONTROLS", "VOLUME_CONTROLS"]
        }
        showJumpControls={false}
      />
    </div>
  );
});

export default AudioPlayer;
