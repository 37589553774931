import React, { useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";

import "./Scanner.scss";
import {
  FaArrowLeft,
  FaRegCheckCircle,
  FaRegTimesCircle,
  FaTimes,
} from "react-icons/fa";
import { IoIosQrScanner } from "react-icons/io";
import Button from "../Button/Button";
import { usePersist } from "../../hooks/usePersist";
import Spacer from "../Spacer/Spacer";
import { useTranslation } from "react-i18next";
import { useClient } from "../../hooks/useClient";
export default function Scanner({
  onScan,
  onClose,
  status,
  errorMessage,
}) {
  const { scanMode, setScanMode } = usePersist();
  let [code, setCode] = useState("");
  useEffect(() => {
    setCode("");
  }, [status, setCode]);
  
  let client = useClient((s) => s.client);
  const { t } = useTranslation();
  return (
    <>
      <div className="Scanner">
        {scanMode === "scan" && (
          <>
            <ScanComponent
              status={status}
              onScan={(result, error) => {
                if (error) {
                  console.info(error);
                }
                if (result && result?.text) {
                  onScan(
                    result?.text.replace("https://"+client.subdomain+".avemus.app/shortLink/", "")
                  );
                }
              }}
            ></ScanComponent>
            <Spacer h={12}></Spacer>
            <Button
              onClick={() => {
                setScanMode("code");
              }}
            >
              {t("Code eingeben")}
            </Button>
          </>
        )}
        {scanMode === "code" && (
          <>
            <KeyPad
              errorMessage={errorMessage}
              status={status}
              value={code}
              onChange={(value) => {
                setCode(value);
                if (value.length === 3) {
                  onScan(value);
                }
              }}
            ></KeyPad>
            <Spacer h={12}></Spacer>
            <Button
              onClick={() => {
                setScanMode("scan");
              }}
            >
              {t("QR-Code scannen")}
            </Button>
          </>
        )}
        {onClose && (
          <>
            <Spacer h={12}></Spacer>
            <Button
              onClick={() => {
                onClose();
              }}
            >
              {t("Schließen")}
            </Button>
          </>
        )}
      </div>
    </>
  );
}

export function ScanComponent({ onScan, status }) {
  const { t } = useTranslation();
  return (
    <div className="scanWarpper shadow">
      {status === "error" && (
        <div className="scannerOverlay">
          <div className="icon error">
            <FaRegTimesCircle />
          </div>
        </div>
      )}
      {status === "success" && (
        <div className="scannerOverlay">
          <div className="icon success">
            <FaRegCheckCircle />
          </div>
        </div>
      )}
      <div className="scanTitle">{t("Code scannen")}</div>
      <div className="viewFinder">
        <IoIosQrScanner />
      </div>
      <QrReader
        containerStyle={{
          flex: 5,
          display: "flex",
          flexDirection: "column",
          borderRadius: 6,
          overflow: "hidden",
        }}
        videoContainerStyle={{
          width: "auto",
          paddingTop: 0,
          backgroundColor: "#222",
          flex: 1,
        }}
        videoStyle={{ objectFit: "cover" }}
        onResult={(result, error) => {
          onScan(result, error);
        }}
        constraints={{ facingMode: "environment" }}
      />
    </div>
  );
}

function KeyPad({ value, onChange, status, errorMessage }) {
  const { t } = useTranslation();
  return (
    <div className="KeyPad shadow">
      {status === "error" && (
        <div className="keypadOverlay">
          <div className="keypadOverlayInner shadow">
            <div className="icon error">
              <FaRegTimesCircle />
            </div>
            <div className="text">{errorMessage}</div>
          </div>
        </div>
      )}
      {status === "success" && (
        <div className="keypadOverlay">
          <div className="keypadOverlayInner shadow">
            <div className="icon success">
              <FaRegCheckCircle />
            </div>
            <div className="text">{t("Richtig!")}</div>
          </div>
        </div>
      )}
      <div className="row titleRow">{t("Code eingeben")}</div>
      <div className="row">
        <button
          className="key shadow"
          onClick={() => {
            onChange(value + "1");
          }}
        >
          1
        </button>
        <button
          className="key shadow"
          onClick={() => {
            onChange(value + "2");
          }}
        >
          2
        </button>
        <button
          className="key shadow"
          onClick={() => {
            onChange(value + "3");
          }}
        >
          3
        </button>
      </div>
      <div className="row">
        <button
          className="key shadow"
          onClick={() => {
            onChange(value + "4");
          }}
        >
          4
        </button>
        <button
          className="key shadow"
          onClick={() => {
            onChange(value + "5");
          }}
        >
          5
        </button>
        <button
          className="key shadow"
          onClick={() => {
            onChange(value + "6");
          }}
        >
          6
        </button>
      </div>
      <div className="row">
        <button
          className="key shadow"
          onClick={() => {
            onChange(value + "7");
          }}
        >
          7
        </button>
        <button
          className="key shadow"
          onClick={() => {
            onChange(value + "8");
          }}
        >
          8
        </button>
        <button
          className="key shadow"
          onClick={() => {
            onChange(value + "9");
          }}
        >
          9
        </button>
      </div>
      <div className="row">
        <button
          className="key shadow iconKey"
          onClick={() => {
            onChange("");
          }}
        >
          <FaTimes></FaTimes>
        </button>
        <button
          className="key shadow"
          onClick={() => {
            onChange(value + "0");
          }}
        >
          0
        </button>
        <button
          className="key shadow iconKey"
          onClick={() => {
            onChange(value.slice(0, -1));
          }}
        >
          <FaArrowLeft></FaArrowLeft>
        </button>
      </div>
      <Spacer h={12}></Spacer>
      <div className="row resRow">
        <div className="value">
          <div className="valueField shadow">{value[0] || ""}</div>
          <div className="valueField shadow">{value[1] || ""}</div>
          <div className="valueField shadow">{value[2] || ""}</div>
        </div>
      </div>
    </div>
  );
}
