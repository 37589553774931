import React from "react";
import { useTranslation } from "react-i18next";
import { BsChevronLeft } from "react-icons/bs";
import { IoMdGlobe, IoIosMenu } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { useClient } from "../../hooks/useClient";
import { usePersist } from "../../hooks/usePersist";
import { ProjectTranslateableDocument } from "../../hooks/useTranslateDocument";
import { LanguageList } from "../LanguageSelector/LanguageSelector";
import MenuOverlay from "../MenuOverlay/MenuOverlay";
import "./Nav.scss";

export default function Nav({ title = "AVEMUS", backUrl, hideBack }) {
  const nav = useNavigate();

  const [langOpen, setLangOpen] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const { client } = useClient();
  const { language, setFontSize } = usePersist();
  const translatedClient = ProjectTranslateableDocument(client, language);
  const { t } = useTranslation();
  return (
    <>
      <nav className="Nav">
        {hideBack ? (
          <div className="backButton iconHolder"></div>
        ) : (
          <div
            className="backButton iconHolder"
            onClick={() => nav(backUrl || -1)}
          >
            <BsChevronLeft></BsChevronLeft>
          </div>
        )}
        <div className="iconHolder"></div>
        <Link to="/" className="headline iconHolder">
          {translatedClient && translatedClient.logo ? (
            <img className="mainLogo" alt="logo" src={translatedClient.logo} />
          ) : (
            "AVEMUS"
          )}
        </Link>
        <div
          className="languageButton iconHolder"
          onClick={() => setLangOpen(true)}
        >
          <IoMdGlobe></IoMdGlobe>
        </div>
        <div
          className="menuButton iconHolder"
          onClick={() => setMenuOpen(true)}
        >
          <IoIosMenu></IoIosMenu>
        </div>
      </nav>
      {menuOpen && (
        <MenuOverlay
          onClose={() => {
            setMenuOpen(false);
          }}
        >
          <div className="menuItems">
            <Link to="/" className="menuItem">
              {t("Home")}
            </Link>
            <Link to="/tours" className="menuItem">
              {t("Führungen")}
            </Link>
            <Link to="/exponate" className="menuItem">
              {t("Exponate")}
            </Link>
            <Link to="/map" className="menuItem">
              {t("Karte")}
            </Link>
            <div
              onClick={() => {
                setMenuOpen(false);
                setLangOpen(true);
              }}
              className="menuItem"
            >
              {t("Sprache")}
            </div>
          </div>
          <div className="flex1"></div>
          <div className="fontSelector">
            <div
              className="smallFont"
              onClick={() => {
                setFontSize("80%");
              }}
            >
              A
            </div>
            <div
              className="mediumFont"
              onClick={() => {
                setFontSize("100%");
              }}
            >
              A
            </div>
            <div
              className="largeFont"
              onClick={() => {
                setFontSize("140%");
              }}
            >
              A
            </div>
          </div>
          <div className="flex1"></div>
          <Link to="/datenschutz" className="menuItem noBorder">
            {t("Datenschutz")}
          </Link>
          <div className="poweredBy">
            <div className="top">powered by</div>
            <div className="bottom">
              <span className="orange">LEMON</span>
              <span className="">VERSE</span>
            </div>
          </div>
        </MenuOverlay>
      )}
      {langOpen && (
        <MenuOverlay
          onClose={() => {
            setLangOpen(false);
          }}
        >
          <LanguageList
            onSelect={() => {
              setLangOpen(false);
            }}
          ></LanguageList>
        </MenuOverlay>
      )}
    </>
  );
}
