const nl = {
  Karte: "Kaart",
  Touren: "Rondleidingen",
  "Sprache ändern": "Taal wijzigen",
  "Interaktiver Museumsbegleiter": "Interactieve museumgids",
  "Geschätzte dauer": "Geschatte duur",
  "Tour starten": "Start tour",
  Exponate: "Exponaten",
  Home: "Home",
  Führungen: "Rondleidingen",
  Sprache: "Taal",
  Datenschutz: "Privacybeleid",
  Schnitzeljagden: "Schnitzeljagden",
  "Die Tour wird geladen": "De tour wordt geladen",
  Bilder: "Afbeeldingen",
  "Mehr erfahren": "Meer informatie",
  Daten: "Gegevens",
  "Erlebe das Exponat in 3D": "Ervaar het object in 3D",
  Videos: "Videos",
  Akzeptieren: "Accepteren",
  "Video von Youtube einbinden?": "Video van Youtube insluiten?",
  "Es gelten die Youtube Datenschutzbedingungen":
    "Youtube privacybeleid is van toepassing",
  "Code eingeben": "Voer code in",
  "QR-Code scannen": "Scan QR-code",
  Schließen: "Sluiten",
  "Code scannen": "Scan code",
  "Richtig!": "Correct!",
  "Gefunden!": "Gevonden!",
  "Leider falsch!": "Helaas verkeerd!",
  "Nicht gefunden.": "Niet gevonden.",
  "Fehler beim Freischalten": "Fout bij het ontgrendelen",
  "Der Code wurde bereits verwendet": "De code is al gebruikt",
  "Freischaltcode eingeben": "Voer ontgrendelingscode in",
  Freischalten: "Ontgrendelen",
  "Jetzt freischalten": "Nu ontgrendelen",
  "Erneut probieren": "Probeer opnieuw",
  "Sie haben den Code": "U heeft de code",
  "gescannt.": "gescand.",
  "Um diesen zum freischalten zu verwenden, klicken Sie auf den Button unten.":
    "Om deze te gebruiken om te ontgrendelen, klik op de knop hieronder.",
  oder: "of",
};
export default nl;
