const en = {
  Karte: "Map",
  Touren: "Tours",
  "Sprache ändern": "Change language",
  "Interaktiver Museumsbegleiter": "Interactive museum guide",
  "Geschätzte dauer": "Estimated duration",
  "Tour starten": "Start tour",
  Exponate: "Exhibits",
  Home: "Home",
  Führungen: "Guides",
  Sprache: "Language",
  Datenschutz: "Privacy Policy",
  Schnitzeljagden: "Treasure hunts",
  "Die Tour wird geladen": "The tour is loading",
  Bilder: "Images",
  "Mehr erfahren": "Learn more",
  Daten: "Data",
  "Erlebe das Exponat in 3D": "Experience the exhibit in 3D",
  Videos: "Videos",
  Akzeptieren: "Accept",
  "Video von Youtube einbinden?": "Embed video from Youtube?",
  "Es gelten die Youtube Datenschutzbedingungen":
    "Youtube privacy policy applies",
  "Code eingeben": "Enter code",
  "QR-Code scannen": "Scan QR-code",
  Schließen: "Close",
  "Code scannen": "Scan code",
  "Richtig!": "Correct!",
  "Gefunden!": "Found!",
  "Leider falsch!": "Unfortunately wrong!",
  "Nicht gefunden.": "Not found.",
  "Fehler beim Freischalten": "Error unlocking",
  "Der Code wurde bereits verwendet": "The code has already been used",
  "Freischaltcode eingeben": "Enter unlock code",
  Freischalten: "Unlock",
  "Jetzt freischalten": "Unlock now",
  "Erneut probieren": "Try again",
  "Sie haben den Code": "You have scanned the code",
  "gescannt.": ".",
  "Um diesen zum freischalten zu verwenden, klicken Sie auf den Button unten.":
    "To use this to unlock, click the button below.",
    "oder": "or"
};
export default en;
