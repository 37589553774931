import React from "react";
import { useParams } from "react-router-dom";
import Layout from "../../atoms/Layout/Layout";
import "./UnlockByUrl.scss";
import Button from "../../atoms/Button/Button";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useClient } from "../../hooks/useClient";
import { usePersist } from "../../hooks/usePersist";
import { useNavigate } from "react-router-dom";

import Loading from "react-loading";
import { FaCheck, FaExclamationTriangle } from "react-icons/fa";
import Spacer from "../../atoms/Spacer/Spacer";
import { useTranslation } from "react-i18next";

export default function UnlockByUrl() {
  const { code } = useParams();
  const functions = getFunctions();
  const sendUnlock = httpsCallable(functions, "useUnlockCode");
  const [status, setStatus] = React.useState("start");
  const { client } = useClient();
  const { setUnlockedUntil } = usePersist();
  const nav = useNavigate();
  const { t } = useTranslation();
  return (
    <Layout className={"UnlockByUrl"}>
      <div>
        {t("Sie haben den Code")}{" "}
        <span style={{ fontWeight: "bold" }}>{code}</span> {t("gescannt.")}
        <br></br>
        {t("Um diesen zum freischalten zu verwenden, klicken Sie auf den Button unten.")}
      </div>
      <Spacer h={12}></Spacer>
      {status === "error" && (
        <>
          <div className="error">{t("Fehler beim Freischalten")}</div>
          <Spacer h={12}></Spacer>
        </>
      )}
      {status === "used" && (
        <>
          <div className="error">{t("Der Code wurde bereits verwendet")}</div>
          <Spacer h={12}></Spacer>
        </>
      )}
      {(status === "start" || status === "loading") && (
        <Button
          onClick={() => {
            if (!["start", "error", "used"].includes(status)) return;
            setStatus("loading");
            sendUnlock({ user: client.id, code: code })
              .then((r) => {
                if (r.data.status === "OK") {
                  setStatus("done");
                  // set unlocked for 24 hours.
                  setUnlockedUntil(new Date().getTime() + 24 * 60 * 60 * 1000);
                  nav("/");
                } else if (r.data.status === "USED") {
                  setStatus("used");
                } else {
                  setStatus("error");
                }
                console.log(r);
              })
              .catch((e) => {
                setStatus("error");
              });
          }}
        >
          {status === "start" && t("Jetzt freischalten")}
          {status === "done" && <FaCheck></FaCheck>}
          {status === "loading" && (
            <Loading
              type="spin"
              color={"white"}
              width={20}
              height={20}
            ></Loading>
          )}
          {status === "error" && (
            <FaExclamationTriangle></FaExclamationTriangle>
          )}
          {status === "used" && <FaExclamationTriangle></FaExclamationTriangle>}
        </Button>
      )}
      {(status === "error" || status === "used") && (
        <Button to={"/unlock"}>{t("Erneut probieren")}</Button>
      )}
    </Layout>
  );
}
