import React from "react";
import { FaMapMarkedAlt } from "react-icons/fa";
import { useParams } from "react-router-dom";
import FBLoader from "../../atoms/FBLoader/FBLoader";
import Layout from "../../atoms/Layout/Layout";

import useQuery from "../../hooks/useQuery";
import "./SingleExponat.scss";
import { useRef } from "react";
import AudioPlayer from "../../atoms/AudioPlayer/AudioPlayer";
import Spacer from "../../atoms/Spacer/Spacer";
import Button from "../../atoms/Button/Button";
import SingleExponatAtom from "../../atoms/SingleExponat/SingleExponat";
export default function SingleExponat() {
  let { id } = useParams();
  //fetch all points

  let audioPlayerRef = useRef();
  let { mappedResults, status,results } = useQuery("exhibits", "id", "==", id);
  console.log(results);
  let data = null;
  if (mappedResults && mappedResults[0]) {
    data = mappedResults[0];
  }

  return (
    <Layout>
      <FBLoader status={status}>
        {data && (
          <SingleExponatAtom extraSpace={170} data={data}></SingleExponatAtom>
        )}
        <div className="floatingPortion">
          {data && data.audio && (
            <>
              <div className="box shadow floatingAudio">
                <AudioPlayer
                  src={data.audio}
                  ref={audioPlayerRef}
                ></AudioPlayer>
              </div>
              <Spacer h={10}></Spacer>
            </>
          )}

          <Button to={"/map/" + id} icon={<FaMapMarkedAlt></FaMapMarkedAlt>}>
            Auf Karte anzeigen
          </Button>
        </div>
      </FBLoader>
    </Layout>
  );
}
