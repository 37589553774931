import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../atoms/Button/Button";
import Layout from "../../atoms/Layout/Layout";
import Spacer from "../../atoms/Spacer/Spacer";
import { usePersist } from "../../hooks/usePersist";
import "./ExponateModeSelect.scss";

export default function ExponateModeSelect() {
  const { setExponateMode } = usePersist();
  const nav = useNavigate();
  return (
    <Layout className="ExponateModeSelect" backUrl={"/"}>
      <div className="expoModeTitle">
      <Spacer h={10}></Spacer>
        Bitte wählen Sie, wie Sie die Exponate sehen möchten:
        <Spacer h={10}></Spacer>
      </div>
      <div className="expoModeButtons">
        <div className="box shadow text">
          Sehen Sie die Exponate auf einer interaktiven Karte an. Diese Funktion
          ist besonders für Leistungsfähige Geräte geeignet.
        </div>
        <Spacer h={10}></Spacer>
        <Button
          onClick={() => {
            setExponateMode("map");
            nav("/map");
          }}
        >
          Kartenansicht
        </Button>
        <Spacer h={48}></Spacer>
        <div className="box shadow text">
          Sehen Sie die Exponate in einer Listenansicht an. Diese Funktion ist
          besonders für Geräte mit geringer Leistungsfähigkeit geeignet.
        </div>
        <Spacer h={10}></Spacer>
        <Button
          onClick={() => {
            setExponateMode("list");
            nav("/exponate");
          }}
        >
          Listenansicht
        </Button>
        
      </div>
      <Spacer h={10}></Spacer>
    </Layout>
  );
}
