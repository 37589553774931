import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../atoms/Layout/Layout";
import "./ShortLink.scss";
import { useClient } from "../../hooks/useClient";
import { getDocs, query, where, collection } from "firebase/firestore";
import { db } from "../../firebase";
import Loading from "react-loading";
import { useEffect } from "react";
import Button from "../../atoms/Button/Button";
import Spacer from "../../atoms/Spacer/Spacer";

export default function ShortLink() {
  const { client } = useClient();
  let { shortId } = useParams();
  const nav = useNavigate();
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    resolveShortLink(shortId, client.id)
      .then(({ targetId, type }) => {
        if (type === "exhibit") {
          nav("/exponat/" + targetId, { replace: true });
        }
        if (type === "tour") {
          nav("/tour/" + targetId, { replace: true });
        }
      })
      .catch((e) => {
        setHasError(true);
      });
  }, [shortId, client.id, nav, setHasError]);

  return (
    <Layout className="ShortLink">
      {!hasError ? (
        <Loading type="spin" />
      ) : (
        <div className="scanError">
          Link nicht gefunden.
          <Spacer h={24}></Spacer>
          <Button to="/scan">
            <Spacer w={12}></Spacer>
            Erneut probieren
            <Spacer w={12}></Spacer>
          </Button>
        </div>
      )}
    </Layout>
  );
}

export async function resolveShortLink(shortId, user) {
  const q = query(
    collection(db, "shortCodes"),
    where("shortId", "==", shortId),
    where("user", "==", user)
  );
  const querySnapshot = await getDocs(q);

  return querySnapshot.docs[0].data();
}
