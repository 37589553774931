import React from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../atoms/Layout/Layout";
import QrScanner from "../../atoms/Scanner/Scanner";
import "./Scanner.scss";

export default function Scanner() {
  const nav = useNavigate();
  return (
    <Layout className="Scanner">
      <QrScanner
        onScan={(code) => {
          nav("/shortLink/" + code);
        }}
      ></QrScanner>
    </Layout>
  );
}
