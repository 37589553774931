import "./App.scss";
import { Routes, Route } from "react-router-dom";
import Home from "./screens/Home/Home";
import Splash from "./screens/Splash/Splash";
import TourList from "./screens/TourList/TourList";
import SingleTour from "./screens/SingleTour/SingleTour";
import { usePersist } from "./hooks/usePersist";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import MapScreen from "./screens/MapScreen/MapScreen";
import TourMap from "./screens/TourMap/TourMap";
import ExponatList from "./screens/ExponatList/ExponatList";
import SingleExponat from "./screens/SingleExponat/SingleExponat";
import Scanner from "./screens/Scanner/Scanner";
import TreasureHunt from "./screens/TreasureHunt/TreasureHunt";
import ShortLink from "./screens/ShortLink/ShortLink";
import Datenschutz from "./screens/Datenschutz/Datenschutz";
import LockedHome from "./screens/LockedHome/LockedHome";
import Unlock from "./screens/Unlock/Unlock";
import UnlockByUrl from "./screens/UnlockByUrl/UnlockByUrl";
import TourComplete from "./screens/TourComplete/TourComplete";
import ExponateModeSelect from "./screens/ExponateModeSelect/ExponateModeSelect";
import Colorizer from "./atoms/Colorizer/Colorizer";
import { useClient } from "./hooks/useClient";

function App() {
  const { language, unlockedUntil } = usePersist();
  let { i18n } = useTranslation();
  let { client } = useClient();
  console.log(client);
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language.toLowerCase());
    }
  }, [language, i18n]);
  return (
    <Splash>
      <Colorizer>
        <Routes>
          {unlockedUntil < new Date().getTime() && !client?.isFree ? (
            <>
              <Route path="/" element={<LockedHome />} />
              <Route path="/unlock" element={<Unlock />} />
              <Route path="/unlock/:code" element={<UnlockByUrl />} />
            </>
          ) : (
            <>
              <Route path="/unlock" element={<Home />} />
              <Route path="/unlock/:code" element={<Home />} />
              <Route path="/" element={<Home />} />
              <Route path="/tours" element={<TourList />} />
              <Route path="/scan" element={<Scanner />} />
              <Route
                path="/exponateModeSelect"
                element={<ExponateModeSelect />}
              />
              <Route path="/datenschutz" element={<Datenschutz />} />
              <Route path="/shortLink/:shortId" element={<ShortLink />} />
              <Route path="/map" element={<MapScreen />} />
              <Route path="/map/:id" element={<MapScreen />} />
              <Route path="/exponate" element={<ExponatList />} />
              <Route path="/exponat/:id" element={<SingleExponat />} />
              <Route path="/tour/:id" element={<SingleTour />} />
              <Route path="/tourComplete" element={<TourComplete />} />
              <Route
                path="/treasure/:id/:station/:solved"
                element={<TreasureHunt />}
              />
              <Route path="/tour/:id/:station" element={<TourMap />} />
            </>
          )}
        </Routes>
      </Colorizer>
    </Splash>
  );
}

export default App;
