import React from "react";
import { Link } from "react-router-dom";
import "./RoundIconButton.scss";

export default function RoundIconButton({
  children,
  to = "/",
  onClick,
  className = "",
}) {
  return (
    <Link
      to={to}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          onClick();
        }
      }}
      className={"RoundIconButton " + className}
    >
      {children}
    </Link>
  );
}
