import React, { useEffect } from "react";
import Loading from "react-loading";
import useQuery from "../../hooks/useQuery";
import { useClient } from "../../hooks/useClient";
import "./Splash.scss";

//load museum info here
export default function Splash({ children }) {
  let { setClient, client } = useClient();
  //fetch client by subdomain or domain
  let subdomain = window.location.hostname.split(".")[0];
  console.log(subdomain)
  if (subdomain === "localhost" || subdomain === "192") {
    subdomain = "asbeck";
  }

  let { results } = useQuery("clients", "subdomain", "==", subdomain);

  useEffect(() => {
    results[0] && setClient(results[0]);
  }, [results, setClient]);
  return client?.id ? children : <Loading type="spin"></Loading>;
}
