import React from "react";
import { useTranslation } from "react-i18next";
import { usePersist } from "../../hooks/usePersist";
import Button from "../Button/Button";
import Spacer from "../Spacer/Spacer";
import "./YoutubeViewer.scss";

export default function YoutubeViewer({ src }) {
  const { hasYoutubeAccepted, setHasYoutubeAccepted } = usePersist();
  const { t } = useTranslation();
  return (
    <div className="box shadow">
      <div className="subTitle">{t("Videos")}</div>
      {hasYoutubeAccepted && (
        <div className="video-container">
          <iframe
            title={"Exponat Video"}
            src={"https://www.youtube.com/embed/" + youtube_parser(src)}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope;"
            allowFullscreen="true"
          ></iframe>
        </div>
      )}
      {!hasYoutubeAccepted && (
        <div className="video-container">
          <div className="videoDialog">
            {t("Video von Youtube einbinden?")}
            <br></br>
            <div>
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Es gelten die Youtube Datenschutzbedingungen")}
              </a>
            </div>
            <Spacer h={12}></Spacer>
            <Button
              onClick={() => {
                setHasYoutubeAccepted(true);
              }}
            >
              <Spacer w={12}></Spacer>
              {t("Akzeptieren")}
              <Spacer w={12}></Spacer>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

function youtube_parser(url) {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
}
