import React from "react";
import Layout from "../../atoms/Layout/Layout";
import "./LockedHome.scss";
import useTranslateDocument from "../../hooks/useTranslateDocument";

import { useTranslation } from "react-i18next";
import Button from "../../atoms/Button/Button";
import Spacer from "../../atoms/Spacer/Spacer";
import { FaLockOpen } from "react-icons/fa";

import { useClient } from "../../hooks/useClient";
export default function LockedHome() {
  const { client } = useClient();
  let { data } = useTranslateDocument("/clients/" + client.id);

  const { t } = useTranslation();
  return (
    <Layout className="Home" hideBack>
      <div className="welcomeImage shadow">
        <img
          alt="museum"
          src={
            data.image ||
            "https://firebasestorage.googleapis.com/v0/b/appvemus.appspot.com/o/1cf9ed48-bc80-4ad1-b963-eea843f29411?alt=media&token=351c8f88-12dc-4e4f-be60-f8828287c4ac"
          }
        ></img>
      </div>
      <div className="MuseumName">
        <div className="nameText">{data.name}</div>
      </div>
      <div className="description">{data.description}</div>
      <Spacer h={160}></Spacer>
      <div className="flex1"></div>
      <div className="buttons">
        <div className="lockedText">
          Die Anwendung ist gesperrt. Bitte entsperren Sie die Anwendung.
        </div>
        <Button icon={<FaLockOpen />} to="/unlock">
          {t("Entsperren")}
        </Button>
        <Spacer h={8}></Spacer>
      </div>
    </Layout>
  );
}
