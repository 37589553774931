import create from "zustand";
import { persist } from "zustand/middleware";
//TODO set i18n language
export const usePersist = create(
  persist(
    (set, get) => ({
      language: null,
      setLanguage: (language) => set({ language }),
      hasCookieAccepted: false,
      setHasCookieAccepted: (hasCookieAccepted) => set({ hasCookieAccepted }),
      isUnlocked: false,
      setIsUnlocked: (isUnlocked) => set({ isUnlocked }),
      exponateMode: null,
      setExponateMode: (exponateMode) => set({ exponateMode }),
      unlockedUntil: 0,
      setUnlockedUntil: (unlockedUntil) => set({ unlockedUntil }),
      hasYoutubeAccepted: false,
      fontSize: "100%",
      lastKnownLocation: null,
      setLastKnownLocation: (lastKnownLocation) => set({ lastKnownLocation }),
      setFontSize: (fontSize) => set({ fontSize }),
      setHasYoutubeAccepted: (hasYoutubeAccepted) =>
        set({ hasYoutubeAccepted }),
      scanMode: "scan",
      setScanMode: (scanMode) => set({ scanMode }),
      t: (item) => {
        if (item.translate) {
          return item.value[get().language] || "";
        } else {
          return item.value["*"] || "";
        }
      },
    }),
    { name: "languageStore45566" }
  )
);
