import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { FaTimes } from "react-icons/fa";

import "./SwipeGallery.scss";

export default function SwipeGallery({ images, onClose = () => {}, start }) {
  const ref = useRef();
  useEffect(() => {
    ref.current.scrollTo(ref.current.offsetWidth * start, 0);
  }, [start]);
  return (
    <div
      className="tourExponatImageOverlay"
      onClick={() => {
        onClose();
      }}
    >
      <div className="SwipeGallery" ref={ref}>
        {images.map((image, i) => {
          return <img alt={"Exponat Bild"} src={images[i]}></img>;
        })}
      </div>
      <div
        className="closeGalleryButton"
        onClick={() => {
          onClose();
        }}
      >
        <FaTimes></FaTimes>
      </div>
    </div>
  );
}
