import React from "react";
import { useTranslation } from "react-i18next";
import { FaRegClock } from "react-icons/fa";
import { FiMapPin, FiUser } from "react-icons/fi";
import { Link } from "react-router-dom";
import FBLoader from "../../atoms/FBLoader/FBLoader";

import Layout from "../../atoms/Layout/Layout";
import QrButton from "../../atoms/QrButton/QrButton";
import Spacer from "../../atoms/Spacer/Spacer";
import { useClient } from "../../hooks/useClient";
import useQuery from "../../hooks/useQuery";
import "./TourList.scss";

export default function TourList() {
  let { client } = useClient();

  let { mappedResults, status } = useQuery("tours", "user", "==", client.id);

  const { t } = useTranslation();
  return (
    <Layout className="TourList" backUrl={"/"}>
      <div className="title">{t("Führungen")}</div>
      <FBLoader status={status}>
        <div className="tourListList">
          {mappedResults
            .filter((t) => t.isEnabled)
            .filter((t) => !t.isTreasureHunt)
            .map((t) => {
              return <TourItem data={t} />;
            })}
          {mappedResults
            .filter((t) => t.isEnabled)
            .filter((t) => t.isTreasureHunt).length > 0 && (
            <>
              <div className="title">{t("Schnitzeljagden")}</div>
              {mappedResults
                .filter((t) => t.isEnabled)
                .filter((t) => t.isTreasureHunt)
                .map((t) => {
                  return <TourItem data={t} />;
                })}
            </>
          )}
          <Spacer h={80}></Spacer>
        </div>
      </FBLoader>
      <QrButton></QrButton>
    </Layout>
  );
}

function TourItem({ data }) {
  return (
    <Link to={"/tour/" + data.id} className="tourLink shadow">
      <div className="tourHero">
        <img
          alt="exhibitImage"
          src={data.image || "https://via.placeholder.com/50"}
        ></img>
      </div>
      <div className="tourName">{data.name || "?"}</div>
      <div className="infos">
        <div className="infoField">
          <div className="icon">
            <FiMapPin />
          </div>
          {data.points.length}
        </div>
        {data.duration && (
          <div className="infoField">
            <div className="icon">
              <FaRegClock />
            </div>
            {data.duration}
          </div>
        )}
        {data.age && (
          <div className="infoField">
            <div className="icon">
              <FiUser />
            </div>
            {data.age}
          </div>
        )}
      </div>
    </Link>
  );
}
