import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useCallback, useEffect, useMemo, useState } from "react";
import { usePersist } from "../../src/hooks/usePersist";
import { db } from "../firebase";

export default function useTranslateDocument(path, autoFetch = true) {
  let { language } = usePersist();
  let [data, setData] = useState({});
  let [status, setStatus] = useState("start");
  let [updateStatus, setUpdateStatus] = useState("start");
  const docRef = useMemo(() => doc(db, path), [path]);

  const reload = useCallback(() => {
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        setStatus("done");
        setData(docSnap.data());
      } else {
        setStatus("error");
        // doc.data() will be undefined in this case
      }
    });
  }, [setStatus, setData, docRef]);

  const updateDocument = useCallback(() => {
    setUpdateStatus("loading");

    return updateDoc(docRef, data).then(() => {
      setUpdateStatus("done");
    });
  }, [data, docRef]);

  const setField = useCallback(
    (key, value, writeImmediate) => {
      let res = {};
      //set field for current language
      if (!data[key]) {
        res = { [key]: { t: true, value: { [language]: value } } };
      } else if (data[key].t === true) {
        res = {
          [key]: {
            ...data[key],
            value: { ...data[key].value, [language]: value },
          },
        };
      } else if (data[key].t === false) {
        res = {
          [key]: {
            ...data[key],
            value: { ...data[key].value, "*": value },
          },
        };
      } else {
        res = {
          [key]: value,
        };
      }

      setData((state) => ({ ...state, ...res }));
      if (writeImmediate) {
        updateDoc(docRef, res).then(() => {
          setUpdateStatus("done");
        });
      }
    },
    [language, data, setUpdateStatus, docRef]
  );

  useEffect(() => {
    if (autoFetch) {
      setStatus("loading");
      reload();
    }
  }, [setStatus, reload, autoFetch]);

  let mappedRes = useMemo(() => {
    return ProjectTranslateableDocument(data, language);
  }, [data, language]);

  return {
    data: mappedRes,
    raw:data,
    setField,
    status,
    reload,
    updateDocument,
    updateStatus,
  };
}

export function ProjectTranslateableDocument(doc, lang) {
  let res = {};
  Object.keys(doc).forEach((k) => {
    let field = doc[k];
    if (field.t === true) {
      //is translateable
      res[k] = field.value[lang] || field.default;
    } else if (field.t === false) {
      res[k] = field.value["*"];
    } else {
      res[k] = field;
    }
  });
  return res;
}
