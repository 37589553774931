import React, { useState } from "react";
import { BsDot } from "react-icons/bs";
import { useRef } from "react";
import Spacer from "../../atoms/Spacer/Spacer";
import Dual3DViewer from "../../atoms/Dual3DViewer/Dual3DViewer";
import SwipeGallery from "../../atoms/SwipeGallery/SwipeGallery";
import YoutubeViewer from "../../atoms/YoutubeViewer/YoutubeViewer";
import { useTranslation } from "react-i18next";

import "./SingleExponat.scss";

export default function SingleExponat({ data, extraSpace=0 }) {
  let ref = useRef();
  const [imageOpen, setImageOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      {data && (
        <>
          <div className="singleExponat">
          <div className="exponatName">{data.name}</div>
            <div className="exponatImages" ref={ref} onClick={() => {}}>
              {data.images.map((image, i) => {
                return (
                  <div className="expoImage ">
                    <img
                      className="shadow"
                      onClick={() => setImageOpen(i)}
                      alt={"Exponat Bild"}
                      src={data.images[i]}
                    ></img>
                  </div>
                );
              })}
            </div>
            <div className="anchors">
              {data.images.map((image, i) => {
                return (
                  <div
                    className="carouselAnchor"
                    onClick={() => {
                      ref.current.scrollTo(ref.current.offsetWidth * i, 0);
                    }}
                    to={"#slide-" + i}
                  >
                    <BsDot />
                  </div>
                );
              })}
            </div>

            <div className="exponatInfo">
    
              {data.description && (
                <div className="exponatDescription">{data.description}</div>
              )}
              <Spacer h={24}></Spacer>
              {Object.keys(data.infos).length > 0 && (
                <>
                  <div className="box shadow">
                    <div className="subTitle">{t("Daten")}</div>
                    <div className="exponatData">
                      {/* map over the data.infos object and for each key, display the .key and .value property in a table-like format */}
                      {data.infos &&
                        Object.keys(data.infos).map((key, i) => {
                          return (
                            <div className="exponatDataItem" key={i}>
                              <div className="exponatDataItemKey">
                                {data.infos[key].key}
                              </div>
                              <div className="exponatDataItemValue">
                                {data.infos[key].value}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <Spacer h={24}></Spacer>
                </>
              )}

              {data.video && <YoutubeViewer src={data.video}></YoutubeViewer>}
              <Spacer h={24}></Spacer>
              {data.model && data.model.file && (
                <div className="box shadow">
                  <div className="subTitle">
                    {t("Erlebe das Exponat in 3D")}
                  </div>
                  <Dual3DViewer data={data.model}></Dual3DViewer>
                </div>
              )}
              <Spacer h={10+extraSpace}></Spacer>
            </div>
          </div>
          {imageOpen !== false && (
            <SwipeGallery
              images={data.images}
              start={imageOpen}
              onClose={() => {
                setImageOpen(false);
              }}
            ></SwipeGallery>
          )}
        </>
      )}
     
    </>
  );
}
