import React, { useState } from "react";
import { FaExpand, FaTimes } from "react-icons/fa";
import ThreeDeeViewer from "../ThreeDeeViewer/ThreeDeeViewer";
import "./Dual3DViewer.scss";

export default function Dual3DViewer({ data }) {
  let [open, setOpen] = useState(false);
  return (
    <>
      <div className="Dual3DViewer">
        <div className="openFullScreenButton" onClick={() => setOpen(true)}>
          <FaExpand></FaExpand>
        </div>
        <ThreeDeeViewer noScroll data={data}></ThreeDeeViewer>
      </div>
      {open && (
        <div className="threeDeeoverlay">
          <div className="closeButton" onClick={() => setOpen(false)}>
            <FaTimes></FaTimes>
          </div>
          <ThreeDeeViewer data={data}></ThreeDeeViewer>
        </div>
      )}
    </>
  );
}
