import React from "react";
import Loading from "react-loading";
import { useClient } from "../../hooks/useClient";
import "./FBLoader.scss";

export default function FBLoader({ status, children }) {
  const { client } = useClient();
  if (status === "loading") {
    return (
      <div className="FBLoader">
        <Loading color={client?.color || "#E33E09"} type="spin"></Loading>
      </div>
    );
  }
  return children;
}
