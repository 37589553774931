import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaRegClock } from "react-icons/fa";
import { FiMapPin, FiUser } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import Button from "../../atoms/Button/Button";
import FBLoader from "../../atoms/FBLoader/FBLoader";
import { collection, query, where, getDocs } from "firebase/firestore";
import Layout from "../../atoms/Layout/Layout";
import Spacer from "../../atoms/Spacer/Spacer";
import useTranslateDocument, {
  ProjectTranslateableDocument,
} from "../../hooks/useTranslateDocument";
import "./SingleTour.scss";
import { db } from "../../firebase";

export default function SingleTour() {
  let { id } = useParams();
  //load tour
  const { t } = useTranslation();
  let { data, status } = useTranslateDocument("/tours/" + id);

  //load points
  return (
    <Layout className="SingleTour" backUrl={"/tours"}>
      <FBLoader status={status}>
        <div className="singleTourInfo">
          {data.image && (
            <img alt="tour" className="tourImage shadow" src={data.image}></img>
          )}
          <div className="tourName">{data.name}</div>
          <div className="tourDesc">{data.text}</div>
          <Spacer h={24}></Spacer>
          {!data.isTreasureHunt && (
            <>
              {data.points && data.points.length > 0 && (
                <PointGrid points={data.points || [null]}></PointGrid>
              )}
              <Spacer h={14}></Spacer>
            </>
          )}
          <div className="tourInfo shadow">
            <div className="tourInfoItem">
              <div className="icon">
                <FiMapPin />
              </div>
              <div className="infoText">
                {data.points && data.points.length}
              </div>
            </div>
            {data.duration && (
              <div className="tourInfoItem">
                <div className="icon">
                  <FaRegClock />
                </div>
                <div className="infoText">{data.duration}</div>
              </div>
            )}
            {data.age && (
              <div className="tourInfoItem">
                <div className="icon">
                  <FiUser />
                </div>
                <div className="infoText">{data.age}</div>
              </div>
            )}
          </div>
          <Spacer h={10}></Spacer>
          <div className="flex1"></div>
          <div className="buttons">
            {data.isTreasureHunt ? (
              <Button to={"/treasure/" + id + "/0/false"}>
                {t("Schnitzeljagd starten")}
              </Button>
            ) : (
              <Button to={"/tour/" + id + "/0"}>{t("Tour starten")}</Button>
            )}
          </div>
          <Spacer h={10}></Spacer>
        </div>
      </FBLoader>
    </Layout>
  );
}

function PointGrid({ points = [null] }) {
  // Split points array into chunks of 10, then request all exhibits in those chunks
  const [exhibits, setExhibits] = React.useState([]);
  useEffect(() => {
    const chunkSize = 10;
    const chunks = [];
    const queries = [];
    for (let i = 0; i < points.length; i += chunkSize) {
      const chunk = points.slice(i, i + chunkSize);
      // do whatever
      chunks.push(chunk);

      const q = query(collection(db, "exhibits"), where("id", "in", chunk));
      queries.push(getDocs(q));
    }
    const data = [];

    Promise.all(queries)
      .then((querySnapshots) => {
        querySnapshots.forEach((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots

            data.push(ProjectTranslateableDocument(doc.data(), "DE"));
          });
        });
      })
      .then(() => {
        setExhibits(data);
      });
  }, [points]);

  const { t } = useTranslation();
  return (
    <div className="pointGrid">
      <div className="title">{t("Exponate")}</div>
      <div className="grid">
        {(points || [])
          .map((p) => {
            return exhibits.find((m) => m.id === p);
          })
          .filter((p) => p)
          .map((e) => {
            return (
              <Link to={"/exponat/" + e.id} className="gridItem shadow">
                <img
                  alt="exhibitImage"
                  src={e.images[0] || "https://via.placeholder.com/50"}
                ></img>
              </Link>
            );
          })}
      </div>
    </div>
  );
}
