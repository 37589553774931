const fr = {
  Karte: "Carte",
  Touren: "Tours",
  "Sprache ändern": "Changer de langue",
  "Interaktiver Museumsbegleiter": "Guide interactif du musée",
  "Geschätzte dauer": "Durée estimée",
  "Tour starten": "Démarrer la visite",
  Exponate: "Expositions",
  Home: "Accueil",
  Führungen: "Visites",
  Sprache: "Langue",
  Datenschutz: "Politique de confidentialité",
  Schnitzeljagden: "Chasses au trésor",
  "Die Tour wird geladen": "Le tour est en cours de chargement",
  Bilder: "Images",
  "Mehr erfahren": "En savoir plus",
  Daten: "Données",
  "Erlebe das Exponat in 3D": "Découvrez l'exposition en 3D",
  Videos: "Vidéos",
  Akzeptieren: "Accepter",
  "Video von Youtube einbinden?": "Intégrer une vidéo de Youtube?",
  "Es gelten die Youtube Datenschutzbedingungen":
    "Les conditions de confidentialité de Youtube s'appliquent",
  "Code eingeben": "Entrer le code",
  "QR-Code scannen": "Scanner le code QR",
  Schließen: "Fermer",
  "Code scannen": "Scanner le code",
  "Richtig!": "Correct!",
  "Gefunden!": "Trouvé!",
  "Leider falsch!": "Malheureusement faux!",
  "Nicht gefunden.": "Non trouvé.",
  "Fehler beim Freischalten": "Erreur de déverrouillage",
  "Der Code wurde bereits verwendet": "Le code a déjà été utilisé",
  "Freischaltcode eingeben": "Entrer le code de déverrouillage",
  Freischalten: "Déverrouiller",
  "Jetzt freischalten": "Déverrouiller maintenant",
  "Erneut probieren": "Réessayer",
  "Sie haben den Code": "Vous avez scanné le code",
  "gescannt.": ".",
  "Um diesen zum freischalten zu verwenden, klicken Sie auf den Button unten.":
    "Pour l'utiliser pour le déverrouillage, cliquez sur le bouton ci-dessous.",
  oder: "ou",
};
export default fr;
