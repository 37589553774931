import React from "react";
import "./QrButton.scss";
import { MdQrCodeScanner } from "react-icons/md";
import Scanner from "../Scanner/Scanner";
import { useNavigate } from "react-router-dom";
import { useClient } from "../../hooks/useClient";
import { resolveShortLink } from "../../screens/ShortLink/ShortLink";
import { useTranslation } from "react-i18next";
export default function QrButton() {
  const [open, setOpen] = React.useState(false);
  const nav = useNavigate();
  const { client } = useClient();
  const [scanStatus, setScanStatus] = React.useState("start");
  const { t } = useTranslation();
  return (
    <>
      <div
        className="QrButton shadow"
        onClick={() => {
          setOpen(true);
        }}
      >
        <MdQrCodeScanner></MdQrCodeScanner>
      </div>
      {open && (
        <Scanner
          status={scanStatus}
          onClose={() => setOpen(false)}
          errorMessage={t("Nicht gefunden.")}
          onScan={(shortId) => {
            resolveShortLink(shortId, client.id)
              .then(({ targetId, type }) => {
                if (type === "exhibit") {
                  nav("/exponat/" + targetId);
                }
                if (type === "tour") {
                  nav("/tour/" + targetId);
                }
              })
              .catch((e) => {
                setScanStatus("error");
                setTimeout(() => {
                  setScanStatus("start");
                }, 3000);
              });
          }}
        ></Scanner>
      )}
    </>
  );
}
