import React from "react";
import "./Modal.scss";
import { FaTimes } from "react-icons/fa";
export default function Modal({ children, onClose }) {
  return (
    <div className="Modal">
      <div className="inner">
        {onClose && (
          <div className="closeModalButton">
            <FaTimes></FaTimes>
          </div>
        )}
        {children}
      </div>
    </div>
  );
}
