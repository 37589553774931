import React from "react";
import { BsChevronLeft } from "react-icons/bs";
import "./MenuOverlay.scss";

export default function MenuOverlay({ children, onClose }) {
  return (
    <div className="MenuOverlay">
      <nav className="Nav">
        <div className="backButton iconHolder" onClick={() => onClose()}>
          <BsChevronLeft></BsChevronLeft>
        </div>
        <div className="iconHolder"></div>
        <div to="/" className="headline iconHolder">
          AVEMUS
        </div>
        <div className="languageButton iconHolder"></div>
        <div className="menuButton iconHolder"></div>
      </nav>
      <div className="inner">

      {children}
      </div>
    </div>
  );
}
