const de_kinder = {
    Karte: "Landkarte",
    Touren: "Abenteuer",
    "Sprache ändern": "Sprache anpassen",
    "Interaktiver Museumsbegleiter": "Spielerischer Museumsfreund",
    "Geschätzte dauer": "Voraussichtliche Zeit",
    "Tour starten": "Abenteuer beginnen",
    Exponate: "Schatzstücke",
    Home: "Zuhause",
    Führungen: "Entdeckungstouren",
    Sprache: "Sprachwahl",
    Datenschutz: "Privatsphäre",
    Schnitzeljagden: "Schatzsuchen",
    "Die Tour wird geladen": "Die Entdeckungsreise lädt",
    Bilder: "Bildergalerie",
    "Mehr erfahren": "Noch mehr entdecken",
    Daten: "Informationen",
    "Erlebe das Exponat in 3D": "Tauche in das Schatzstück in 3D ein",
    Videos: "Filme",
    Akzeptieren: "Zustimmen",
    "Video von Youtube einbinden?": "Ein YouTube-Video anschauen?",
    "Es gelten die Youtube Datenschutzbedingungen": "Es gelten die Youtube Datenschutzbedingungen",
    "Code eingeben": "Geheimen Code eingeben",
    "QR-Code scannen": "QR-Code lesen",
    Schließen: "Schließen",
    "Code scannen": "Code lesen",
    "Richtig!": "Richtig gemacht!",
    "Gefunden!": "Gefunden!",
    "Leider falsch!": "Leider nicht richtig!",
    "Nicht gefunden.": "Nicht gefunden.",
    "Fehler beim Freischalten": "Fehler beim Öffnen",
    "Der Code wurde bereits verwendet": "Dieser Code wurde schon benutzt",
    "Freischaltcode eingeben": "Öffnungscode eingeben",
    Freischalten: "Öffnen",
    "Jetzt freischalten": "Jetzt öffnen",
    "Erneut probieren": "Nochmal versuchen",
    "Sie haben den Code": "Du hast den Code",
    "gescannt.": "gelesen",
    "Um diesen zum freischalten zu verwenden, klicken Sie auf den Button unten.":
      "Klicke auf den Button unten, um ihn zu öffnen.",
    oder: "oder",
  };
  export default de_kinder;
  