import React from "react";
import Layout from "../../atoms/Layout/Layout";
import "./Datenschutz.scss";

export default function Datenschutz() {
  return (
    <Layout className="Datenschutz">
      <div className="padded">
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Hic voluptatum
        earum dignissimos amet accusantium nemo eligendi eius, facere ex rerum,
        voluptates cumque itaque in ut cupiditate sequi vero provident sint?
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Hic voluptatum
        earum dignissimos amet accusantium nemo eligendi eius, facere ex rerum,
        voluptates cumque itaque in ut cupiditate sequi vero provident sint?
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Hic voluptatum
        earum dignissimos amet accusantium nemo eligendi eius, facere ex rerum,
        voluptates cumque itaque in ut cupiditate sequi vero provident sint?
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Hic voluptatum
        earum dignissimos amet accusantium nemo eligendi eius, facere ex rerum,
        voluptates cumque itaque in ut cupiditate sequi vero provident sint?
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Hic voluptatum
        earum dignissimos amet accusantium nemo eligendi eius, facere ex rerum,
        voluptates cumque itaque in ut cupiditate sequi vero provident sint?
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Hic voluptatum
        earum dignissimos amet accusantium nemo eligendi eius, facere ex rerum,
        voluptates cumque itaque in ut cupiditate sequi vero provident sint?
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Hic voluptatum
        earum dignissimos amet accusantium nemo eligendi eius, facere ex rerum,
        voluptates cumque itaque in ut cupiditate sequi vero provident sint?
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Hic voluptatum
        earum dignissimos amet accusantium nemo eligendi eius, facere ex rerum,
        voluptates cumque itaque in ut cupiditate sequi vero provident sint?
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Hic voluptatum
        earum dignissimos amet accusantium nemo eligendi eius, facere ex rerum,
        voluptates cumque itaque in ut cupiditate sequi vero provident sint?
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Hic voluptatum
        earum dignissimos amet accusantium nemo eligendi eius, facere ex rerum,
        voluptates cumque itaque in ut cupiditate sequi vero provident sint?
      </div>
    </Layout>
  );
}
