import React, { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Layout from "../../atoms/Layout/Layout";
import Map, { DumnDot } from "../../atoms/Map/Map";
import QrButton from "../../atoms/QrButton/QrButton";

import { useClient } from "../../hooks/useClient";
import useQuery from "../../hooks/useQuery";
import { TourExponatOverlay } from "../TourMap/TourMap";
import "./MapScreen.scss";

export default function MapScreen() {
  let { client } = useClient();
  //fetch all points
  let { mappedResults } = useQuery("exhibits", "user", "==", client.id);
  let nav = useNavigate();
  let { id } = useParams();
  const camRef = useRef();
  let selectedExponat = null;
  if (id) {
    selectedExponat = mappedResults.find((exhibit) => {
      return exhibit.id === id;
    });
  }

  useEffect(() => {
    if (camRef.current && selectedExponat) {
      camRef.current.moveTo(
        selectedExponat.point.x,
        selectedExponat.point.y - 2.5,
        30,
        true
      );
      camRef.current.zoomTo(60, true);
    }
  }, [selectedExponat]);
  return (
    <Layout className="MapScreen" backUrl={"/"}>
      <Map ref={camRef}>
        {mappedResults &&
          mappedResults.map((exhibit) => {
            return (
              <DumnDot
                data={exhibit}
                key={exhibit.id}
                onClick={() => {
                  !exhibit.isPOI && nav("/map/" + exhibit.id);
                }}
              ></DumnDot>
            );
          })}
      </Map>
      {selectedExponat && (
        <TourExponatOverlay
          data={selectedExponat}
          onClose={() => {
            nav("/map");
          }}
        ></TourExponatOverlay>
      )}
      {!selectedExponat && <QrButton></QrButton>}
    </Layout>
  );
}
